import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogout, selectAdminToken } from "../../store/_reducers/admin";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { adminService } from "../../_services/admin.service";
import { IoDocumentTextOutline } from "react-icons/io5";

function AdminDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState("");
  const token = useSelector(selectAdminToken);
  const [isInputVisible, setInputVisible] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [selectedConnectionId, setSelectedConnectionId] = useState(null);
  const handleLogout = () => {
    dispatch(adminLogout());
    navigate("/adminlogin");
  };
  const [connections, setConnections] = useState([]);
  const [name, setName] = useState("");
  const [users, setUsers] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters long")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });
  const handlePasswordChange = async (values, { resetForm }) => {
    const { password, confirmPassword } = values;

    try {
      const response = await adminService.changeAdminPassword(
        token,
        password,
        confirmPassword
      );
      if (response.success) {
        toast.success("Password successfully changed!");
        resetForm();
      } else {
        setError("Failed to change password. Please try again.");
        toast.error("Failed to change password. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while changing the password.");
      console.error("Password change error:", err);
    }
  };

  const fetchConnections = async () => {
    try {
      const response = await adminService.getConnection(token);
      if (response.success) {
        setConnections(response.data);
        if (response.data.length > 0) {
          const firstConnection = response.data[0];
          setClientId(firstConnection.clientId);
          setClientSecret(firstConnection.clientSecret);
          if (selectedConnectionId === null) {
            setSelectedConnectionId(firstConnection.id);
          }
        }
      } else {
        toast.error("Failed to load connections.");
      }
    } catch (err) {
      toast.error("No Connections Found");
      console.error("Error fetching connections:", err);
    }
  };
  useEffect(() => {
    fetchConnections();
  }, [token]);
  const handleConnectionSelect = (connectionId) => {
    setSelectedConnectionId(connectionId);
    const selectedConnection = connections.find(
      (connection) => connection.id === connectionId
    );
    if (selectedConnection) {
      setClientId(selectedConnection.clientId);
      setClientSecret(selectedConnection.clientSecret);
    }
  };

  const handleButtonClick = () => {
    setInputVisible((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    try {
      const response = await adminService.createConnection(name, token);
      if (response.success) {
        fetchConnections();
        toast.success("Connection created successfully!");
        setInputVisible(false);
        setName("");
      } else {
        toast.error("Failed to create connection.");
      }
    } catch (err) {
      toast.error("An error occurred while creating the connection.");
      console.error("Error creating connection:", err);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await adminService.getUser(token);
      if (response.success) {
        setUsers(response.data);
      } else {
        toast.error("Failed to load Users.");
      }
    } catch (err) {
      toast.error("No Users Found");
      console.error("Error fetching Users:", err);
    }
  };
  useEffect(() => {
    fetchUser();
  }, [token]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileContent = event.target.result;
        parseCSV(fileContent);
      };

      reader.readAsText(file);
    }
  };

  const parseCSV = (fileContent) => {
    const rows = fileContent.split("\n");
    if (rows.length > 0) {
      const row = rows[0].split(",");
      if (row.length >= 2) {
        console.log("CSV Data: ", row);
      }
    }
  };

  const handleFormSubmit = async () => {
    if (!clientId || !clientSecret) {
      toast.error("Please provide both Client ID and Client Secret.");
      return;
    }

    if (!selectedConnectionId) {
      toast.error("Please select a connection.");
      return;
    }

    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("clientSecret", clientSecret);
    formData.append("isNew", isEditable);

    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput?.files[0];
    if (file) {
      formData.append("file", file);
    }
    if (!file) {
      toast.error("Please select a file.");
      return;
    }
    try {
      const response = await adminService.updateConnection(
        selectedConnectionId,
        formData,
        token
      );

      if (response.success) {
        toast.success("Connection updated successfully!");
        if (fileInput) fileInput.value = "";
      } else {
        toast.error("Failed to update connection.");
      }
    } catch (err) {
      toast.error("An error occurred while updating the connection.");
      console.error("Error updating connection:", err);
    }
  };
  return (
    <>
      <div className="container-fluid px-0">
        <div className="row m-0 ">
          <div className="col-md-12 px-0">
            <div className="d-flex justify-content-center align-items-center py-3 header-t">
              <h1 className="text-white">Badge+</h1>
              <div className="logout">
                <button className="btn logout-btn" onClick={handleLogout}>
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-md-12 px-0">
            <div className="tab-style">
              <Tabs
                defaultActiveKey="Connections"
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="Connections" title="Connections">
                  <div className="row m-0">
                    <div className="col-md-3 px-0">
                      <ul className="list-style">
                        {Array.isArray(connections) &&
                        connections.length > 0 ? (
                          connections.map((connection) => (
                            <li key={connection.id}>
                              <a
                                href="#"
                                className="d-flex justify-content-between align-items-center"
                                onClick={() =>
                                  handleConnectionSelect(connection.id)
                                }
                              >
                                <span>{connection.name}</span>
                                <svg
                                  width="20"
                                  height="20"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={
                                    selectedConnectionId === connection.id
                                      ? "active-icon"
                                      : ""
                                  }
                                >
                                  <polygon
                                    points="10,0 20,20 0,20"
                                    fill={
                                      selectedConnectionId === connection.id
                                        ? "white"
                                        : "current"
                                    }
                                    transform="rotate(90 10 10)"
                                  ></polygon>
                                </svg>
                              </a>
                            </li>
                          ))
                        ) : (
                          <li className="no-connection">
                            <span>No connections found</span>
                          </li>
                        )}
                      </ul>
                      <div className="ms-2">
                        {!isInputVisible ? (
                          <button
                            className="btn  py-2 btn-primary w-100"
                            onClick={handleButtonClick}
                          >
                            New Connection
                          </button>
                        ) : (
                          <div>
                            <input
                              type="text"
                              placeholder="Enter connection details"
                              className="form-control mb-2"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            <button
                              className="btn btn-success w-100"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-8">
                      <div className="connection-name p-5">
                        <h5>Connection Name</h5>
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder={
                            selectedConnectionId
                              ? connections.find(
                                  (c) => c.id === selectedConnectionId
                                )?.name
                              : "Select a connection"
                          }
                          disabled
                        />
                        <p className="text-center">Current DB CSV</p>
                        <div className="mb-3 text-center">
                          <IoDocumentTextOutline className="docs" />
                        </div>
                        <input
                          type="file"
                          accept=".csv"
                          className="form-control"
                          onChange={handleFileChange}
                        />
                        {/* <button className="btn btn-primary py-2 d-block m-auto w-100">
                          Upload User DB
                        </button> */}
                      </div>
                    </div>
                    <div className="col-xl-5 col-md-12 mt-5">
                      <div className="comman-card ">
                        <div className="comman-title">
                          <h2>Input API Credentials</h2>
                        </div>
                        <div className="comman-form">
                          <div className="mb-3 d-flex align-items-center justify-content-center">
                            <input
                              type="checkbox"
                              id="editClientCredentials"
                              checked={isEditable}
                              onChange={() => setIsEditable(!isEditable)}
                            />
                            <label
                              htmlFor="editClientCredentials"
                              className="ms-2"
                            >
                              Is New Credentials
                            </label>
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              name="Client ID"
                              className="form-control"
                              placeholder="Client ID"
                              value={clientId}
                              onChange={(e) => setClientId(e.target.value)}
                              readOnly={!isEditable}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              name="clientSecret"
                              className="form-control"
                              placeholder="Client Secret"
                              value={clientSecret}
                              onChange={(e) => setClientSecret(e.target.value)}
                              readOnly={!isEditable}
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() =>
                              handleFormSubmit(selectedConnectionId)
                            }
                          >
                            SYNC
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="AdminDashboard" title="AdminDashboard">
                  <div className="row m-0">
                    <div className="col-md-4 mx-auto mt-5 px-0">
                      <h5 className="mb-3">Change password</h5>

                      {error && (
                        <div className="alert alert-danger">{error}</div>
                      )}

                      <Formik
                        initialValues={{
                          password: "",
                          confirmPassword: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handlePasswordChange}
                      >
                        {({ touched, errors }) => (
                          <Form>
                            <div className="mb-3">
                              <Field
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="New Password"
                              />
                              {touched.password && errors.password && (
                                <div className="text-danger">
                                  {errors.password}
                                </div>
                              )}
                            </div>

                            <div className="mb-3">
                              <Field
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Confirm Password"
                              />
                              {touched.confirmPassword &&
                                errors.confirmPassword && (
                                  <div className="text-danger">
                                    {errors.confirmPassword}
                                  </div>
                                )}
                            </div>

                            <button
                              type="submit"
                              className="btn py-2 btn-primary w-100"
                            >
                              Change Password
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="User Mgr" title="User Mgr">
                  <div className="row m-0">
                    <div className="col-md-3 px-0">
                      <ul className="list-style">
                        {Array.isArray(connections) &&
                        connections.length > 0 ? (
                          connections.map((connection) => (
                            <li key={connection.id}>
                              <a
                                href="#"
                                className="d-flex justify-content-between align-items-center"
                                onClick={() =>
                                  setSelectedConnection(connection.name)
                                }
                              >
                                <span>{connection.name}</span>
                                <svg
                                  width="20"
                                  height="20"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={
                                    selectedConnection === connection.name
                                      ? "active-icon"
                                      : ""
                                  }
                                >
                                  <polygon
                                    points="10,0 20,20 0,20"
                                    fill={
                                      selectedConnection === connection.name
                                        ? "white"
                                        : "current"
                                    }
                                    transform="rotate(90 10 10)"
                                  ></polygon>
                                </svg>
                              </a>
                            </li>
                          ))
                        ) : (
                          <li className="no-connection">
                            <span>No connections found</span>
                          </li>
                        )}
                      </ul>
                      <div className="ms-2">
                        {!isInputVisible ? (
                          <button
                            className="btn py-2 btn-primary w-100"
                            onClick={handleButtonClick}
                          >
                            New Connection
                          </button>
                        ) : (
                          <div>
                            <input
                              type="text"
                              placeholder="Enter connection details"
                              className="form-control mb-2"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            <button
                              className="btn btn-success w-100"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-9  mt-5 px-0">
                      <div className="list-container">
                        <ul className="list">
                          <li className="list-item">
                            <span>User</span>
                            <span>User ID</span>
                            <span>Organization ID</span>
                          </li>
                          {users.length > 0 ? (
                            users.map((user) => (
                              <li key={user.userId} className="list-item">
                                <span>
                                  <a href={`mailto:${user.email}`}>
                                    {user.name}
                                  </a>
                                </span>
                                <span>{user.email}</span>
                                <span>{user.organizationId}</span>
                              </li>
                            ))
                          ) : (
                            <li className="list-item">No users found.</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminDashboard;
