import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  sauceToken: null,
  connectionId: null,
  isTemp: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.connectionId = action.payload.connectionId;
      state.isTemp = action.payload.isTemp;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.sauceToken = null;
      state.connectionId = null;
      state.isTemp = false;
    },

    setSauceToken: (state, action) => {
      state.sauceToken = action.payload;
    },
  },
});

export const { login, logout, setSauceToken, connectionId } = authSlice.actions;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;
export const getUserToken = (state) => state.auth.token;
export const getTicketSauceToken = (state) => state.auth.sauceToken;
export const getConnectionId = (state) => state.auth.connectionId;
export const getisTemp = (state) => state.auth.isTemp;
export default authSlice.reducer;
