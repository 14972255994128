import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { userService } from "../../_services/user.service";
import { useDispatch, useSelector } from "react-redux";
import { login, selectIsAuthenticated } from "../../store/_reducers/auth";
import toast from "react-hot-toast";
const Login = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setErrorMessage(null);
    try {
      const result = await userService.loginUser(values.email, values.password);

      if (result.success) {
        toast.success("Login successful!");
        dispatch(
          login({
            user: result.user,
            token: result.token,
            connectionId: result.connectionId,
            isTemp: result.isTemp,
          })
        );
        navigate("/dashboard");
        resetForm();
      } else {
        setErrorMessage(result.error);
      }
    } catch (error) {
      toast.error("Invalid email/password combo. Please try again later.");
      setErrorMessage("Invalid email/password combo. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-container">
      <h1 className="title text-white">BADGE+</h1>
      <div className="login-card">
        <div className="login-title">
          <h2>Login</h2>
        </div>
        <div className="login-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />
                </div>

                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isSubmitting}
                >
                  LOGIN
                </button>
              </Form>
            )}
          </Formik>

          <div className="links-container">
            <div className="forgot-password">
              <Link to="/forgot">Forgot Password</Link>
            </div>
            <div className="or-text">OR</div>
            <div className="create-account">
              <Link to="/register" className="create-account">
                Create Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
