import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserToken, selectIsAuthenticated } from "../store/_reducers/auth";

const ProtectedRoute = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(getUserToken);
  const auth = !isAuthenticated && !token;
  if (auth) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
