import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userService } from "../../_services/user.service";
import toast from "react-hot-toast";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setErrorMessage(null);

    try {
      const result = await userService.forgotPassword(values.email);

      if (result.success) {
        toast.success("Link Send Successfully On Your Mail!");
        navigate("/");
        resetForm();
      } else {
        setErrorMessage(result.error);
      }
    } catch (error) {
      toast.error("User Not Found");
      setErrorMessage("An unexpected error occurred. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-container">
      <h1 className="title text-white">BADGE+</h1>
      <div className="login-card">
        <div className="login-title">
          <h2>Forgot Password</h2>
          <p className="msg">
            Please Enter the Email corresponding to your Event Management
            Account
          </p>
        </div>

        <div className="login-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
