import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import {
  selectAdminAuthenticated,
  selectAdminToken,
} from "../store/_reducers/admin";

const PrivateRoutes = () => {
  const isAdminAuthenticated = useSelector(selectAdminAuthenticated);
  const adminToken = useSelector(selectAdminToken);
  const admin = !isAdminAuthenticated && !adminToken;
  if (admin) {
    return <Navigate to="/adminlogin" />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
