import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserToken,
  logout,
  setSauceToken,
  getTicketSauceToken,
  getConnectionId,
  getisTemp,
} from "../../store/_reducers/auth";
import { useNavigate } from "react-router-dom";
import { userService } from "../../_services/user.service";
import toast from "react-hot-toast";
import { Field, Form, Formik } from "formik";

function Dashboard() {
  const [eventList, setEventList] = useState([]);
  const [printerStatus, setPrinterStatus] = useState("none");
  const [loading, setLoading] = useState(true);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] =
    useState(false);
  const [isScreenDisabled, setIsScreenDisabled] = useState(false);
  const [error, setError] = useState("");

  const isTemp = useSelector(getisTemp);
  const dispatch = useDispatch();
  const token = useSelector(getUserToken);
  const connectionId = useSelector(getConnectionId);
  const accessToken = useSelector(getTicketSauceToken);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPrinterStatus = () => {
      const statuses = ["none", "connected", "standby"];
      setPrinterStatus(statuses[Math.floor(Math.random() * statuses.length)]);
    };
    checkPrinterStatus();
    const interval = setInterval(checkPrinterStatus, 9000);
    return () => clearInterval(interval);
  }, []);

  const handlePrinterSetup = () => {
    window.print();
  };
  const handleButtonClick = () => {
    navigate("/badge-design");
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "connected":
        return "green";
      case "standby":
        return "yellow";
      case "none":
        return "red";
      default:
        return "red";
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const getSauceToken = async () => {
    try {
      const response = await userService.getSauceToken(token, connectionId);
      const fetchedAccessToken = response.data.accessToken;
      dispatch(setSauceToken(fetchedAccessToken));
    } catch (err) {
      toast.error("No Ticket Sauce Token Found");
      console.error("Error fetching Ticket Sauce Token:", err);
    }
  };

  useEffect(() => {
    if (token) {
      getSauceToken();
    }
  }, [token]);

  const fetchEventList = async () => {
    if (accessToken) {
      try {
        setLoading(true);
        const response = await userService.getEventList(accessToken, token);
        setEventList(response.data || []);
      } catch (err) {
        toast.error("Failed to fetch event list");
        console.error("Error fetching event list:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchEventList();
  }, [accessToken, token]);

  const handlePasswordChange = async (values, { resetForm }) => {
    const { password, confirmPassword } = values;

    try {
      const response = await userService.changeUserPassword(
        token,
        password,
        confirmPassword
      );
      if (response.success) {
        toast.success("Password successfully changed!");
        resetForm();
        setIsPasswordChangeModalOpen(false);
      } else {
        setError("Failed to change password. Please try again.");
        toast.error("Failed to change password. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while changing the password.");
      console.error("Password change error:", err);
    }
  };

  return (
    <div
      className={`container-fluidb px-3 pt-5 text-center dashboard ${
        isScreenDisabled ? "disabled-screen" : ""
      }`}
    >
      {isTemp && (
        <div
          className="alert alert-warning"
          role="alert"
          style={{
            position: "relative",
            width: "100%",
            zIndex: 1050,
            textAlign: "center",
          }}
          onClick={() => {
            setIsPasswordChangeModalOpen(true);
            setIsScreenDisabled(true);
          }}
        >
          You are currently using a temporary password. Please set a new
          password immediately.
        </div>
      )}

      {isPasswordChangeModalOpen && (
        <div
          className="modal fade show change-pass-modal"
          tabIndex="-1"
          style={{ display: "block" }}
          aria-labelledby="passwordChangeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="passwordChangeModalLabel">
                  Change Your Password
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setIsPasswordChangeModalOpen(false);
                    setIsScreenDisabled(false);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  onSubmit={handlePasswordChange}
                >
                  {({ handleSubmit, values, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <Field
                          type="password"
                          name="password"
                          className="form-control"
                          value={values.password}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {error && <p className="text-white">{error}</p>}
                      <button type="submit" className="btn btn-primary">
                        Change Password
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setIsPasswordChangeModalOpen(false);
              setIsScreenDisabled(false);
            }}
          >
            Close
          </button>
        </div> */}
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-12 py-3">
          <div className="d-flex justify-content-end align-items-center">
            <button className="btn logout-btn mt-4" onClick={handleLogout}>
              Log Out
            </button>
          </div>
          <div className="page-title mb-3">
            <h1>Badge+</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="row">
            {loading ? (
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            ) : Array.isArray(eventList) && eventList.length > 0 ? (
              eventList.map((eventItem) => {
                const event = eventItem.Event;
                const logo = eventItem.Logo;

                return (
                  <div key={event.id} className="col-md-4">
                    <div className="card">
                      {logo && (
                        <div className="card-logo">
                          <img
                            src={logo.url || "/images/1.jpg"}
                            alt={event.name}
                            className="img-fluid"
                          />
                        </div>
                      )}
                      <div className="card-body">
                        <h5 className="card-title">{event.name}</h5>
                        <p className="card-text">
                          {event.start} - {event.end}
                        </p>
                        <p className="card-text">
                          {event.location}, {event.city}, {event.state}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No events available.</p>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="footer fixed-bottom">
            <div className="d-flex mob justify-content-between align-items-center border border-dark px-4 py-2">
              <div className="d-flex align-items-center gap-5">
                <button
                  className="btn dashbord-btn"
                  onClick={handlePrinterSetup}
                >
                  Printer Setup
                </button>
                <div className="d-flex align-items-center gap-2">
                  Printer Status:
                  <div
                    className="status-indicator"
                    style={{
                      backgroundColor: getStatusColor(printerStatus),
                    }}
                  ></div>
                </div>
              </div>
              <button onClick={handleButtonClick} className="btn dashbord-btn">
                Badge Designer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
