import axios from "axios";
export const userService = {
  checkUser,
  registerUser,
  loginUser,
  getSauceToken,
  getEventList,
  forgotPassword,
  changeUserPassword,
};
const API = process.env.REACT_APP_API_URL;
async function checkUser(email) {
  try {
    const response = await axios.post(
      `${API}/checkuser`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error checking user:", error);
  }
}
async function registerUser({ email, password, confirmPassword }) {
  try {
    const response = await axios.put(
      `${API}/register`,
      { email, password, confirmPassword },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error registering user:", error);
  }
}
async function loginUser(email, password) {
  try {
    const response = await axios.post(
      `${API}/login`,
      { email, password },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
  }
}

async function getSauceToken(token, connectionId) {
  try {
    const response = await axios.post(
      `${API}/ticket/get-access-token`,
      { connectionId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
    console.error("Get ticket sauce token error:", error);
  }
}

async function getEventList(accessToken, token) {
  try {
    const response = await axios.post(
      `${API}/ticket/eventlist`,
      { accessToken },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
    console.error("Error fetching event list:", error);
  }
}

async function forgotPassword(email) {
  try {
    const response = await axios.post(
      `${API}/forget-password`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error checking user:", error);
  }
}

async function changeUserPassword(token, password, confirmPassword) {
  try {
    const response = await axios.put(
      `${API}/change-user-password`,
      { password, confirmPassword },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error changing admin password:", error);
  }
}
