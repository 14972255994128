import React, { useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { Stage, Layer, Rect, Text, Ellipse, Group } from "react-konva";

const BadgeDesign = () => {
  const [showContactOptions, setShowContactOptions] = useState(false);
  const [canvasSize, setCanvasSize] = useState({ width: 288, height: 432 });
  const [elements, setElements] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState("0");
  const [selectedFont, setSelectedFont] = useState("Poppins");

  const [selectedSize, setSelectedSize] = useState("option1");
  const [selectedFields, setSelectedFields] = useState({
    Name: false,
    Title: false,
    Company: false,
    "QR Code Ticket": false,
    "Contact Card": false,
    "Questionnaire Answer #1": false,
    "Questionnaire Answer #2": false,
  });
  const [contactCardFields, setContactCardFields] = useState({
    phone: false,
    email: false,
    address: false,
  });

  const size = [
    { id: "option1", label: '4"x6"', width: 288, height: 432 },
    { id: "option2", label: '3"x4"', width: 216, height: 288 },
  ];

  const field = [
    { id: 1, label: "Name" },
    { id: 2, label: "Title" },
    { id: 3, label: "Company" },
    { id: 4, label: "QR Code Ticket" },
    { id: 5, label: "Contact Card" },
    { id: 6, label: "Questionnaire Answer #1" },
    { id: 7, label: "Questionnaire Answer #2" },
  ];

  const font = [
    { id: 1, label: "Poppins" },
    { id: 2, label: "Arial" },
    { id: 3, label: "Oswald" },
  ];

  const contactCardOptions = [
    { id: "phone", label: "Phone Number" },
    { id: "email", label: "Email Address" },
    { id: "address", label: "Address" },
  ];

  const handleContactCardClick = (e) => {
    setShowContactOptions(e.target.checked);
  };

  const handleSizeChange = (id) => {
    setSelectedSize(id);
    const selectedSize = size.find((option) => option.id === id);
    if (selectedSize) {
      setCanvasSize({ width: selectedSize.width, height: selectedSize.height });
    }
  };

  const toggleField = (label, isChecked) => {
    if (label === "QR Code Ticket") {
      return;
    }

    setSelectedFields((prev) => ({
      ...prev,
      [label]: isChecked,
    }));

    if (isChecked) {
      setElements((prev) => [
        ...prev,
        {
          id: `${label}-${Date.now()}`,
          type: label,
          x: canvasSize.width / 2,
          y: canvasSize.height / 2,
          text: label,
          fontFamily: selectedFont,
          draggable: true,
        },
      ]);
    } else {
      setElements((prev) => prev.filter((el) => el.type !== label));
    }
  };

  const handleDragMove = (id, e) => {
    const { x, y } = e.target.position();
    setElements((prev) =>
      prev.map((el) => (el.id === id ? { ...el, x, y } : el))
    );
  };

  const handleTextChange = (id, text) => {
    setElements((prev) =>
      prev.map((el) => (el.id === id ? { ...el, text } : el))
    );
    console.log(`Updated ${id}: ${text}`);
  };

  const handleAccordionToggle = (eventKey) => {
    setActiveAccordion((prevKey) => (prevKey === eventKey ? "" : eventKey));
  };

  const handleFontChange = (font) => {
    setSelectedFont(font);
    setElements((prevElements) =>
      prevElements.map((el) => ({
        ...el,
        fontFamily: font,
      }))
    );
  };

  const handleContactCardFieldChange = (id) => {
    setContactCardFields((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <>
      <div className="badge_design-wrapper">
        <div className="badge_design-container">
          <div className="badge_design-container-header">
            <h1 className="text-muted text-center m-0 py-3">Badge Designer</h1>
          </div>
          <div className="badge_design-container-body">
            <Stage width={canvasSize.width} height={canvasSize.height}>
              <Layer>
                <Rect
                  x={50}
                  y={50}
                  width={canvasSize.width - 100}
                  height={canvasSize.height - 100}
                  fill="white"
                  cornerRadius={10}
                />
                {elements.map((element) => {
                  if (element.type === "QR Code Ticket") {
                    return null;
                  }

                  return (
                    <Group
                      key={element.id}
                      draggable
                      x={element.x}
                      y={element.y}
                      onDragMove={(e) => handleDragMove(element.id, e)}
                    >
                      <Ellipse
                        width={150}
                        height={50}
                        fill="white"
                        stroke="black"
                        strokeWidth={2}
                      />
                      <Text
                        text={element.text}
                        fontSize={18}
                        fontFamily={element.fontFamily || selectedFont}
                        align="center"
                        verticalAlign="middle"
                        width={150}
                        height={50}
                        x={-75}
                        y={-25}
                        onClick={() => {
                          const newText = prompt(
                            `Edit ${element.type}:`,
                            element.text
                          );
                          if (newText !== null) {
                            handleTextChange(element.id, newText);
                          }
                        }}
                      />
                    </Group>
                  );
                })}
              </Layer>
            </Stage>
          </div>
        </div>
        <div className="badge_design-sidebar">
          <div className="badge_design-sidebar-header">
            <h1 className="text-black text-center m-0 py-2">Menu</h1>
          </div>
          <div className="badge_design-sidebar-body">
            <Accordion
              className="badge_design-accordion"
              activeKey={activeAccordion}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => handleAccordionToggle("0")}>
                  Badge Size
                </Accordion.Header>
                {activeAccordion === "0" && (
                  <Accordion.Body>
                    {size.map((option) => (
                      <Form.Check key={option.id} type="radio" isValid>
                        <Form.Check.Input
                          type="radio"
                          isValid
                          name="size"
                          onChange={() => handleSizeChange(option.id)}
                          checked={option.id === selectedSize}
                        />
                        <Form.Check.Label>{option.label}</Form.Check.Label>
                      </Form.Check>
                    ))}
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header onClick={() => handleAccordionToggle("1")}>
                  Fields
                </Accordion.Header>
                {activeAccordion === "1" && (
                  <Accordion.Body>
                    {field.map((option) => (
                      <Form.Check key={option.id} type="checkbox" isValid>
                        <Form.Check.Input
                          type="checkbox"
                          isValid
                          checked={selectedFields[option.label] || false}
                          onChange={(e) => {
                            toggleField(option.label, e.target.checked);
                            if (option.label === "Contact Card") {
                              handleContactCardClick(e);
                            }
                          }}
                        />
                        <Form.Check.Label>{option.label}</Form.Check.Label>
                        {option.label === "Contact Card" && showContactOptions && (
                          <div className="ms-3 mt-2">
                            {contactCardOptions.map((contactOption) => (
                              <Form.Check
                                key={contactOption.id}
                                type="checkbox"
                                isValid
                                checked={contactCardFields[contactOption.id] || false}
                                onChange={() => handleContactCardFieldChange(contactOption.id)}
                              >
                                <Form.Check.Input type="checkbox" isValid />
                                <Form.Check.Label>
                                  {contactOption.label}
                                </Form.Check.Label>
                              </Form.Check>
                            ))}
                          </div>
                        )}
                      </Form.Check>
                    ))}
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header onClick={() => handleAccordionToggle("2")}>
                  Fonts
                </Accordion.Header>
                {activeAccordion === "2" && (
                  <Accordion.Body>
                    {font.map((option) => (
                      <Form.Check key={option.id} type="radio" isValid>
                        <Form.Check.Input
                          type="radio"
                          isValid
                          name="font"
                          checked={option.label === selectedFont}
                          onChange={() => handleFontChange(option.label)}
                        />
                        <Form.Check.Label>{option.label}</Form.Check.Label>
                      </Form.Check>
                    ))}
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="badge_design-sidebar-footer">
            <Button className="badge_design-save-btn">Save</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BadgeDesign;
