import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  admin: null,
  token: null,
  isAdmin : true
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    adminLogin: (state, action) => {
      state.isAuthenticated = true;
      state.admin = action.payload.admin;
      state.token = action.payload.token;
      state.isAdmin = action.payload.isAdmin;
    },
    adminLogout: (state) => {
      state.isAuthenticated = false;
      state.admin = null;
      state.token = null;
      state.isAdmin = true;
    },
  },
});

export const { adminLogin, adminLogout } = adminSlice.actions;
export const selectAdminAuthenticated = (state) => state.admin.isAuthenticated;
export const selectAdmin = (state) => state.admin.admin;
export const selectAdminToken = (state) => state.admin.token;
export const isAdmin = (state) => state.admin.isAdmin;
export default adminSlice.reducer;
