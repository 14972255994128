import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userService } from "../../_services/user.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const User = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setErrorMessage(null);

    try {
      const result = await userService.registerUser(values);

      if (result.success) {
        toast.success("Registration successful");
        navigate("/");
        console.log("Registration successful", result.data);

        resetForm();
      } else {
        setErrorMessage(result.error);
      }
    } catch (error) {
      toast.error("User Not Found");
      setErrorMessage(
        "Sorry, that user does not exist. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-container">
      <h1 className="title text-white">BADGE+</h1>
      <div className="login-card">
        <div className="login-title">
          <h2>Create Account</h2>
          <p className="msg">
            Please Enter the Email corresponding to your Event Management
            Account
          </p>
        </div>

        <div className="login-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error-message"
                  />
                </div>

                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Creating Account..." : "Create Account"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default User;
