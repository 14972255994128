import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../store/_reducers/auth";
import adminReducer from "../store/_reducers/admin";

const persistConfig = {
  key: "auth",
  storage,
};

const persistAdminConfig = {
  key: "admin",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedAdminReducer = persistReducer(persistAdminConfig, adminReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    admin: persistedAdminReducer,
  },
});
export const persistor = persistStore(store);
export default store;
