import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLogin,
  selectAdminAuthenticated,
} from "../../store/_reducers/admin";
import { adminService } from "../../_services/admin.service";

const Admin = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });
  const isAdminAuthenticated = useSelector(selectAdminAuthenticated);
  if (isAdminAuthenticated) {
    return <Navigate to="/admindashboard" />;
  }
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setErrorMessage(null);
    try {
      const result = await adminService.loginAdmin(
        values.email,
        values.password
      );

      if (result.success) {
        dispatch(
          adminLogin({
            admin: result.admin,
            token: result.token,
            permissions: result.permissions,
          })
        );
        toast.success("Login successful!");
        navigate("/admindashboard");
        resetForm();
      } else {
        setErrorMessage(result.error);
      }
    } catch (error) {
      toast.error("Invalid email/password combo. Please try again later.");
      setErrorMessage("Invalid email/password combo. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-container">
      <h1 className="title text-white">BADGE+</h1>
      <div className="login-card">
        <div className="login-title">
          <h2>Admin Login</h2>
        </div>
        <div className="login-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />
                </div>

                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isSubmitting}
                >
                  LOGIN
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Admin;
