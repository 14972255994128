import axios from "axios";
export const adminService = {
  createConnection,
  getConnection,
  loginAdmin,
  changeAdminPassword,
  getUser,
  updateConnection,
};
const API = process.env.REACT_APP_API_URL;

async function createConnection(name, token) {
  try {
    const response = await axios.post(
      `${API}/connection/create-connection`,
      { name },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Create connection error:", error);
  }
}

async function getConnection(token) {
  try {
    const response = await axios.get(`${API}/connection/get-connections`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Get connection error:", error);
  }
}
async function loginAdmin(email, password) {
  try {
    const response = await axios.post(
      `${API}/admin/login`,
      { email, password },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
  }
}

async function changeAdminPassword(token, password, confirmPassword) {
  try {
    const response = await axios.put(
      `${API}/admin/change-admin-password`,
      { password, confirmPassword },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error changing admin password:", error);
  }
}

async function getUser(token) {
  try {
    const response = await axios.get(`${API}/admin/get-users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Get User error:", error);
  }
}
async function updateConnection(connectionId, formData, token) {
  try {
    const response = await axios.put(
      `${API}/connection/update-connection/${connectionId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating connection:", error);
  }
}
