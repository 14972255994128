import React from "react";
import { ThemeProvider } from "../context/ThemeProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login/index";
import Register from "../pages/register/index";
import User from "../pages/register/createuser";
import Dashboard from "../pages/dashboard";
import Admin from "../pages/superadmin/adminlogin";
import AdminDashboard from "../pages/superadmin";
import ProtectedRoute from "../components/ProtectedRoutes";
import PrivateRoutes from "../components/PrivateRoutes";
import ForgotPassword from "../pages/forgotpassword";
import BadgeDesign from "../pages/badgedesign";

const AppRouter = () => {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="createuser" element={<User />} />
          <Route path="adminlogin" element={<Admin />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="badge-design" element={<BadgeDesign />} />
          <Route element={<ProtectedRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="admindashboard" element={<AdminDashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default AppRouter;
